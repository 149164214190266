import React from 'react'

const About = () => {
  return (
   <>
   <h2>About Page</h2>
   </>
  )
}

export default About
