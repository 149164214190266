import React from 'react'

const Aasdev = () => {
  return (
    <div>
      <h1>AAS Dev</h1>
      <p>This is a development page for the AAS. It will contain information about how to develop and contribute to this project.</p>
    </div>
  )
}

export default Aasdev
