import React from 'react'

const Whatwedo = () => {
  return (
    <div>
      <h1>This is a What we do page.</h1>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio nam earum illum maiores labore nulla deleniti autem incidunt modi fuga ullam molestias officia nemo amet, veniam repellendus? Vel accusantium itaque inventore aspernatur eaque, quam reiciendis!</p>

    </div>
  )
}

export default Whatwedo
