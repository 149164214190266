import React from 'react'

const Whyus = () => {
  return (
    <div>
        <h1>This is a Why us Page.</h1>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi est laboriosam fugit tempora quasi incidunt error consequuntur maiores maxime iste cum praesentium sunt alias, architecto dolor totam deleniti illo dolorem aspernatur non nihil nobis? Ea.</p>
      
    </div>
  )
}

export default Whyus
